import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { auth as actions } from "actions";
import GoogleLogin from "react-google-login";
import GoogleButton from "components/elements/Google_Button";
import { returnGoogleClientId } from "functions";

class SocialLogins extends Component {
  responseGoogle = async (res) => {
    if (res.error) {
      console.log("res.error", res.error);
      return;
    }

    await this.props.googleLogIn(res.tokenId, this.props.history);
  };

  render() {
    const { login, register, messages, locale } = this.props;

    return (
      <div style={{ paddingBottom: 20, paddingTop: 10 }}>
        <div className="buttons">
          <GoogleLogin
            clientId={returnGoogleClientId()}
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            render={(renderProps) => (
              <GoogleButton {...this.props} onClick={renderProps.onClick}>
                {login
                  ? messages.google.login[locale]
                  : register && messages.google.register[locale]}
              </GoogleButton>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      google: state.intl.messages.components.buttons.google,
    },
  };
};

export default connect(mapStateToProps, actions)(withRouter(SocialLogins));
